/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

// 16进制 转 10进制
export function hex2int(hex) {
  console.log(hex, 'hex2inthex2int')
  if (hex) {
    let size = ''
    if (typeof hex == 'number') {
      return hex
    } else {
      size = hex.slice(1)
      var len = size.length
      var a = new Array(len)
      var code
      for (var i = 0; i < len; i++) {
        code = size.charCodeAt(i)
        if (code >= 48 && code < 58) {
          code -= 48
        } else {
          code = (code & 0xdf) - 65 + 10
        }
        a[i] = code
      }
      return a.reduce(function(acc, c) {
        acc = 16 * acc + c
        return acc
      }, 0)
    }
  }
}

// 10进制 转 16进制
// 这个函数有严重的问题 不要再使用
export function int2hex(num, width) {
  var hex = '0123456789abcdef'
  var s = ''
  while (num) {
    s = hex.charAt(num % 16) + s
    num = Math.floor(num / 16)
  }
  if (typeof width === 'undefined' || width <= s.length) {
    return s
  }
  var delta = width - s.length
  var padding = ''
  while (delta-- > 0) {
    padding += '0'
  }
  return padding + s
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) s++
    else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xdc00 && code <= 0xdfff) i--
  }
  return s
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
      '"}'
  )
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

// 电话号码验证
export function telTest(tel) {
  return /^1[345678]\d{9}$/.test(tel)
}

// 图片验证，如果图片加载失败则替换会默认图片
export function checkPic(
  item,
  picKey,
  setPic,
  defaultPic = require('@/assets/img/user.png')
) {
  let url = setPic ? setPic : item[picKey]
  url += '?v=' + Date.parse(new Date()) // 增加时间戳防止加载了图片缓存
  let img = new Image()
  img.src = url
  return new Promise((resolve, reject) => {
    img.onload = () => {
      resolve(url)
    }
    img.onerror = function() {
      url = defaultPic
      if (item) item[picKey] = defaultPic
      reject(url)
    }
  })
}

/**
 * 前端数据检索，支持多项检索
 * @param {val}    // 检索值
 * @param {from}   // 被检索的字段
 * @param {arr}    // 被检索的数组对象
 */
export function searchFunc(val, from, arr) {
  var result = []
  Object.keys(from).forEach(e => {
    var a = filterFunc(val, e, arr)
    for (var i = 0; i < a.length; i++) {
      result.push(a[i])
    }
  })
  return result
}

function filterFunc(val, target, filterArr) {
  if (val == undefined || val == '') {
    return filterArr
  }
  return filterArr.filter(p => {
    return p[target].indexOf(val) > -1
  })
}

// rem转PX函数
export const changePX = data => {
  return (((data / 46.875) * document.documentElement.clientWidth) / 320) * 20
}

//判断浏览器是否为移动端
export const isMoblie = () => {
  return !!navigator.userAgent.match(/AppleWebKit.*Mobile.*/)
}

// 洗牌算法 用来抽最终考试的题目
export const getMess = (arr, num) => {
  console.log(arr)
  let newArr = JSON.parse(JSON.stringify(arr))
  let list = newArr.sort(() => {
    return Math.random() - 0.5
  })
  return list.slice(0, num)
}

//判断是否不能用H5的storage
export const noStorage = () => {
  return (
    navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ==
      'micromessenger' ||
    navigator.userAgent
      .toLowerCase()
      .toString()
      .indexOf('qqbrowser') > -1
  )
}

// 存本地缓存或cookie
export const setData = (name, value) => {
  localStorage.setItem(name, value)
}
// 从本地缓存或cookie
export const getData = name => {
  return localStorage.getItem(name)
}
// 删除本地缓存或cookie
export const delData = name => {
  localStorage.removeItem(name)
}

// 存cookie
function setCookie(name, value) {
  let exp = new Date()
  let time = 60 * 60 * 1000 * 24 * 2
  exp.setTime(exp.getTime() + time)
  document.cookie = `${name}=${value};expires=${exp.toGMTString()};path=/;domain=.shundecity.com`
}

//读取cookies
function getCookie(name) {
  let arr,
    reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  if ((arr = document.cookie.match(reg))) {
    return unescape(arr[2])
  } else {
    return null
  }
}

//删除cookies
function delCookie(name) {
  let exp = new Date()
  let time = 60 * 60 * 1000 * 24 * 2
  exp.setTime(exp.getTime() - time)
  let cval = getCookie(name)
  if (cval != null)
    document.cookie = `${name}=${cval};expires=${exp.toGMTString()};path=/;domain=.shundecity.com`
}

/**
 * 防抖
 * @param {Function} func 要执行的回调函数
 * @param {Number} delay 延时的时间
 */

export const _debounce = (func, delay = 300) => {
  let timer
  return () => {
    let context = this
    let args = arguments
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(context, args)
    }, delay)
  }
}

/**
 * 节流
 * @param {Function} func 要执行的回调函数
 * @param {Number} delay 延时的时间
 */
export const _throttle = (func, delay = 300) => {
  let timer
  return () => {
    let context = this
    let args = arguments
    if (timer) return false
    timer = setTimeout(() => {
      clearTimeout(timer)
      timer = null
    }, delay)
    func.apply(context, args)
  }
}

// 生成全球唯一标识GUID （参考：https://blog.csdn.net/Alive_tree/article/details/87942348）
export function createGguid() {
  return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

// 关闭界面，整合微信/钉钉的关闭动作
export function allClose() {
  try {
    // 微信关闭
    WeixinJSBridge.invoke('closeWindow', {}, function(res) {})
  } catch {}
  try {
    // 钉钉关闭 （非ios）
    dd.biz.navigation.close({
      onSuccess: () => {}
    })
  } catch {}
  try {
    // 钉钉关闭 （ios）
    dd.biz.navigation.quit({
      message: 'quit message', //退出信息，传递给openModal或者openSlidePanel的onSuccess函数的result参数
      onSuccess: function(result) {
        /**/
      },
      onFail: function() {}
    })
  } catch {}
  // 普通浏览器关闭
  window.close()
}

// 方法定义 lat,lng 单位m
export function GetDistance(lat1, lng1, lat2, lng2) {
  var radLat1 = (lat1 * Math.PI) / 180.0
  var radLat2 = (lat2 * Math.PI) / 180.0
  var a = radLat1 - radLat2
  var b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0
  var s =
    2 *
    Math.asin(
      Math.sqrt(
        Math.pow(Math.sin(a / 2), 2) +
          Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)
      )
    )
  s = s * 6378137 // EARTH_RADIUS;
  s = Math.round(s * 10000) / 10000
  return s
}

/**
 * 根据页数切割table数据，得到各分页数据
 * params     pageSize-页数 list-table数据
 * return     [[...], [...]]
 */
export function paging(size = 1, list = []) {
  const total = Math.ceil(list.length / size); // 页数
  let res = [];
  for (let i = 1; i <= total; i++) {
    const arr = list.slice((i - 1) * size, i * size); // 切割
    res.push(arr); // 从1开始  [(页码-1)*每页数，页码*每页数 - 1]
  }
  return res;
}

/**
 * 获取相机权限
 */
export function requestPermissionsCAMERA() {
  let getOpenApp = sessionStorage.getItem('isOpenApp')
  var u = navigator.userAgent;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if(getOpenApp == 1 && isAndroid){
    plus.android.requestPermissions(['android.permission.CAMERA'], function(e){  
      if(e.deniedAlways.length>0){    //权限被永久拒绝  
        // 弹出提示框解释为何需要权限，引导用户打开设置页面开启  
        console.log('权限被永久拒绝'+e.deniedAlways.toString());  
      }  
      if(e.deniedPresent.length>0){   //权限被临时拒绝  
        // 弹出提示框解释为何需要权限，可再次调用plus.android.requestPermissions申请权限  
        console.log('权限被临时拒绝'+e.deniedPresent.toString());  
      }  
      if(e.granted.length>0){ //权限被允许  
        console.log('权限被允许'+e.granted.toString());  
      }  
    }, function(e){  
      console.log('Request Permissions error:'+JSON.stringify(e));  
    });
  }
  
}